import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Section from "../../atoms/Section"
import ImageTextMolecule from "../../molecules/ImageText/ImageTextMolecule"

import * as Utils from "./utils"
import { cloneDeep } from "lodash"

const ImageTextSection = props => {
  const {
    isMediaPositioningFloating,
    mediaPositioning,
    verticalPadding,
    textChild,
    // overlap,
    // conditionalProps,
    // customPadding,
  } = Utils.useMemoizedProps(props)

  const newProps = cloneDeep(props)

  let layoutChangerStyles = {}

  if (
    props.section.verticalPadding === "fullHeight" &&
    props.mediaPositioning !== "floating"
  ) {
    layoutChangerStyles.height = { _: "100%", md: "100vh" }
  }
  const hideBg = props.transparentBg === false ? true : false

  return (
    <Section
      {...props.section}
      verticalPadding={verticalPadding}
      verticalText={props.verticalText}
      noStandardPadding={!isMediaPositioningFloating || hideBg ? true : false}
      openModalSectionById={props?.openModalSectionById}
      customVerticalPadding={
        mediaPositioning !== "floating"
          ? -1
          : props.section.customVerticalPadding
      }
      maxWidth={mediaPositioning === "fit" ? "100%" : props.section.maxWidth}
      margin={props.section?.margin ? props.section?.margin : "0px auto"} // setting transparent nav margin when feature is turned on
      hideBg={hideBg}
    >
      <ImageTextMolecule
        {...newProps}
        textChild={textChild}
        align={props.alignProp}
        layoutChangerStyles={layoutChangerStyles}
        isMarginBtwChildsAPixelValue={true}
      />
    </Section>
  )
}

export default ImageTextSection

ImageTextSection.propTypes = {
  strapi_id: PropTypes.number.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
}

export const query = graphql`
  fragment ImageTextSection on STRAPI__COMPONENT_SECTIONS_IMAGE_TEXT {
    strapi_id
    section {
      ...Section
    }
    button {
      ...Button
    }
    button2 {
      ...Button
    }
    imageOrVideo {
      ...ImageOrVideo
    }
    text {
      ...SectionTitle
    }
    card {
      ...Card
    }
    verticalText {
      ...VerticalText
    }
    socialLinks {
      ...SocialLink
    }
    socialLinkStyles {
      ...SocialLinkStyles
    }
    iconAttribute {
      ...IconAttribute
    }
    iconStyles {
      ...IconStyles
    }
    newsletter {
      ...Newsletter
    }
    child1Px
    layoutStyle
    alignProp
    location
    mediaLocation
    mediaRatio
    mediaWidth
    useNewsletter
    xOffsetOverlap
    yOffsetOverlap
    mediaPositioning
    mediaPositioningOnMobile
    marginBtwChilds
    mobileMarginBtwChilds
  }
`
